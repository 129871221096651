import './App.scss';
import { createClient } from '@supabase/supabase-js';
import { useState, useEffect, useRef } from 'react';

const perPage = 100;
const supabase = createClient(
	'https://eydhsvvzuurodjmgwdrp.supabase.co',
	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYyMDQzNjk3MiwiZXhwIjoxOTM2MDEyOTcyfQ.P14evHJLPKGfc-E7qJsShi5AJbgX3pJX0oAovWaoXIs'
);
const DEFUALT_PICTURE = `https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ft4.ftcdn.net%2Fjpg%2F00%2F64%2F67%2F63%2F240_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg&f=1&nofb=1`;
const CDN_URL = `https://cdn.woke.country/file/wokecountry`;
const leftColor = '#efefef';
const leftTextColor = '#333';
const rightColor = '#0f85ff';
const rightTextColor = '#efefef';

function App() {
	const [messages, setMessages] = useState([]);
	const [users, setUsers] = useState([]);
	const [cursor, setCursor] = useState(0);
	const [loaded, setLoaded] = useState(false);
	const [loadingMore, setLoadingMore] = useState(false);
	const endRef = useRef();
	const messageListRef = useRef();
	messageListRef.current = messages;
	const userListRef = useRef();
	userListRef.current = users;
	const cursorRef = useRef();
	cursorRef.current = cursor;

	const appendMessages = (newMessages) => {
		setMessages(
			newMessages
				.concat(messageListRef.current)
				.sort((a, b) => (a.date < b.date ? -1 : 1))
		);
	};
	const appendUsers = (newUsers) => {
		setUsers(newUsers.concat(userListRef.current));
	};

	const makeUserRequest = async () => {
		const { data } = await supabase.from('posters').select('*');

		setUsers(data);
	};

	const makeRequest = async (index) => {
		const rangeStart = index || cursorRef.current;
		console.log(rangeStart);
		const { data } = await supabase
			.from('messages')
			.select('*')
			.order('date', { ascending: false })
			.range(rangeStart, rangeStart + perPage);

		data.reverse();
		console.log('Set to', rangeStart + data.length + 1);
		setCursor(rangeStart + data.length);
		console.log(data);
		appendMessages(data);
	};

	const isTop = (el) => {
		return el.getBoundingClientRect().top == 0;
	};

	const scrollListen = async () => {
		const wrappedElement = document.getElementById('messageList');
		if (isTop(wrappedElement) && !loadingMore) {
			setLoadingMore(true);
			await makeRequest(cursor);
			setLoadingMore(false);
		}
	};

	useEffect(async () => {
		await makeRequest(cursor);
		await makeUserRequest();

		setTimeout(() => {
			document.addEventListener('scroll', scrollListen);
		});

		if (!endRef) return;
		console.log(endRef);
		setTimeout(() => {
			setLoaded(true);
			endRef.current.scrollIntoView({ behavior: 'auto' });
		}, 2000);

		supabase
			.from('messages')
			.on('INSERT', (payload) => {
				console.log(messages, payload.new);
				appendMessages([payload.new]);
			})
			.subscribe();

		supabase
			.from('messages')
			.on('UPDATE', (payload) => {
				const index = messageListRef.current.indexOf(
					messageListRef.current.filter((i) => i.id == payload.new.id)[0]
				);
				const copy = [...messageListRef.current];
				copy[index] = payload.new;
				console.log(copy, messageListRef.current, payload);
				setMessages(copy);
				console.log('Change received!', payload);
			})
			.subscribe();

		supabase
			.from('messages')
			.on('DELETE', (payload) => {
				const index = messageListRef.current.indexOf(
					messageListRef.current.filter((i) => i.id == payload.old.id)[0]
				);
				const copy = [...messageListRef.current];
				copy.splice(index, 1);
				console.log(copy, messageListRef.current, payload);
				setMessages(copy);
			})
			.subscribe();

		supabase
			.from('posters')
			.on('INSERT', (payload) => {
				console.log('POSTER INSERT', payload, '=>', userListRef.current);
				appendUsers([payload.new]);
			})
			.subscribe();

		supabase
			.from('posters')
			.on('UPDATE', (payload) => {
				console.log('POSTER UPDATE', payload, '=>', userListRef.current);
				const index = userListRef.current.indexOf(
					userListRef.current.filter((i) => i.id == payload.new.id)[0]
				);
				const copy = [...userListRef.current];
				copy[index] = payload.new;
				console.log(copy, userListRef.current, payload);
				setUsers(copy);
				console.log('Change received!', payload);
			})
			.subscribe();

		supabase
			.from('posters')
			.on('DELETE', (payload) => {
				console.log('POSTER DELETE', payload, 'X', userListRef.current);
				const index = userListRef.current.indexOf(
					userListRef.current.filter((i) => i.id == payload.old.id)[0]
				);
				const copy = [...userListRef.current];
				copy.splice(index, 1);
				console.log(copy, userListRef.current, payload);
				setUsers(copy);
			})
			.subscribe();
	}, []);

	const filteredMessages = messages;

	return (
		<div className='App'>
			<div className='clownBG'></div>
			<div className='header'>
				<div className='users'>
					{users.map((user, i) => (
						<div className='profile' key={'profile-' + i}>
							<img
								src={
									user.avatar
										? `${CDN_URL}/${user.avatar}.png`
										: DEFUALT_PICTURE
								}
								className='profilePic'></img>
							<p style={{ fontWeight: '900', paddingTop: 5 }}>
								{user.name || 'Anonymous'}
							</p>
							<pre>{user.id}</pre>
						</div>
					))}
				</div>
			</div>
			<div
				className='messages'
				style={{ opacity: loaded ? 1 : 0 }}
				id='messageList'>
				{users.length > 0 &&
					filteredMessages.map((message, i) => {
						const data = JSON.parse(message.data);
						const next = JSON.parse(filteredMessages[i + 1]?.data || '{}');
						const prev = JSON.parse(filteredMessages[i - 1]?.data || '{}');

						const lastInSeries = !next.author || next.author != data.author;
						const firstInSeries = !prev.author || prev.author != data.author;
						const user = users.filter((ui) => ui.id == data.author)[0] || {};

						const onRight = users.indexOf(user) == 0;
						const backgroundColor = onRight ? rightColor : leftColor;
						const textColor = onRight ? rightTextColor : leftTextColor;
						const text = (data.text || '').replace(/<@!(\S*)>/g, (match) => {
							const id = match.replace('<@!', '').replace('>', '');
							const userMatch = users.filter((ui) => ui.id == id)[0];
							if (!userMatch || !userMatch.name) return '@Anonymous';
							return '@' + userMatch.name;
						});

						return (
							<div
								style={{ display: 'flex', flexDirection: 'column' }}
								key={'message-' + i}>
								{firstInSeries && (
									<p
										style={{
											textAlign: onRight ? 'right' : 'left',
											paddingRight: onRight ? 90 : 0,
											paddingLeft: !onRight ? 90 : 0,
											paddingTop: 10,
											fontWeight: '900',
										}}>
										{user?.name || 'Anonymous'}
									</p>
								)}

								<div
									className={onRight ? 'messageOuterRight' : 'messageOuterLeft'}
									style={
										onRight
											? { justifyContent: 'flex-end', marginLeft: 'auto' }
											: { justifyContent: 'flex-start', marginRight: 'auto' }
									}>
									{!onRight && (
										<>
											{lastInSeries ? (
												<img
													src={
														user.avatar
															? `${CDN_URL}/${user.avatar}.png`
															: DEFUALT_PICTURE
													}
													className='profilePic'></img>
											) : (
												<div className='profilePic' />
											)}
										</>
									)}
									<div
										className='message'
										style={{ backgroundColor, overflow: 'hidden' }}>
										{data.attachments &&
											data.attachments.map((attachment, ai) => {
												const isVideo = attachment.media.indexOf('.mp4') != -1;
												const isIframe = attachment.media.indexOf('http') != -1;
												return (
													<div
														className='mediaElement'
														key={'att' + i + '-' + ai}>
														{isIframe ? (
															<iframe
																src={attachment.media}
																frameBorder={0}
																style={{
																	width: '100%',
																	maxWidth: 350,
																	minHeight: 250,
																}}></iframe>
														) : isVideo ? (
															<video
																src={
																	attachment.media.indexOf('http') != -1
																		? attachment.media
																		: CDN_URL + '/' + attachment.media
																}
																controls
																style={{
																	width: '100%',
																	maxWidth: 350,
																}}></video>
														) : (
															<img
																src={CDN_URL + '/' + attachment.media}
																style={{ width: '100%' }}></img>
														)}
														{attachment.author ? (
															<p
																style={{
																	padding: 10,
																	paddingBottom: 20,
																	color: textColor,
																	paddingTop: 15,
																	backgroundColor: text
																		? 'rgba(0,0,0,0.3)'
																		: 'transparent',
																	marginTop: -5,
																}}>
																from {attachment.author}
																<br></br>
																<br></br>
																{attachment.message}
															</p>
														) : (
															<div style={{ height: 10 }} />
														)}
													</div>
												);
											})}
										{text && (
											<p
												style={{
													color: textColor,
												}}>
												{text}
											</p>
										)}
									</div>

									{onRight && (
										<img
											src={
												user.avatar
													? `${CDN_URL}/${user.avatar}.png`
													: DEFUALT_PICTURE
											}
											className='profilePic'
											style={
												lastInSeries ? { opacity: 1 } : { opacity: 0 }
											}></img>
									)}
								</div>
							</div>
						);
					})}
				<div style={{ float: 'left', clear: 'both' }} ref={endRef}></div>
			</div>
		</div>
	);
}

export default App;
